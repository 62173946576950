import React, { useState } from "react";
import "./App.css";
import {
  countriesToOptions as CountriesToOptions,
  countries,
} from "./getOptions";

function App() {
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  return (
    <div className="App">
      <header className="App-header">Can I COVID?</header>
      <main className="forms">
        <label>
          Travelling from
          <select
            value={fromCountry}
            onChange={(e) => setFromCountry(e.target.value)}
          >
            <CountriesToOptions countryList={countries} />
          </select>
        </label>
        <label>
          Travelling to
          <select
            value={toCountry}
            onChange={(e) => setToCountry(e.target.value)}
          >
            <CountriesToOptions
              countryList={countries.filter(
                (x) => !fromCountry || x !== fromCountry
              )}
            />
          </select>
        </label>
        {fromCountry && toCountry && (
          <article>
            <h1>Restrictions:</h1>
            <span>
              Travelling from {fromCountry} to {toCountry}, you need to do the
              following:
            </span>
            <p></p>
          </article>
        )}
      </main>
    </div>
  );
}

export default App;
